import React, { useEffect, useRef } from "react";
import Layout from "components/Layout";

export default function ProAppointments() {
  const pageRef = useRef();

  useEffect(() => {
    const _pageRef = pageRef?.current ?? null;
    const _script = document.createElement("script");

    if (_pageRef) {
      _script.src =
        "https://squareup.com/appointments/buyer/widget/xt3fu8pil9dpbm/LSD00QJE50ZQG.js";
      _script.async = true;
      _pageRef.appendChild(_script);
    }

    return () => _pageRef?.removeChild(_script) ?? null;
  }, [pageRef]);

  return (
    <Layout>
      <div
        ref={pageRef}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100vw",
        }}
      />
    </Layout>
  );
}
